exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-data-js": () => import("./../../../src/pages/data.js" /* webpackChunkName: "component---src-pages-data-js" */),
  "component---src-pages-diy-ab-js": () => import("./../../../src/pages/diy/ab.js" /* webpackChunkName: "component---src-pages-diy-ab-js" */),
  "component---src-pages-diy-bc-js": () => import("./../../../src/pages/diy/bc.js" /* webpackChunkName: "component---src-pages-diy-bc-js" */),
  "component---src-pages-diy-burlington-js": () => import("./../../../src/pages/diy/burlington.js" /* webpackChunkName: "component---src-pages-diy-burlington-js" */),
  "component---src-pages-diy-canada-js": () => import("./../../../src/pages/diy/canada.js" /* webpackChunkName: "component---src-pages-diy-canada-js" */),
  "component---src-pages-diy-mb-js": () => import("./../../../src/pages/diy/mb.js" /* webpackChunkName: "component---src-pages-diy-mb-js" */),
  "component---src-pages-diy-nb-js": () => import("./../../../src/pages/diy/nb.js" /* webpackChunkName: "component---src-pages-diy-nb-js" */),
  "component---src-pages-diy-nl-js": () => import("./../../../src/pages/diy/nl.js" /* webpackChunkName: "component---src-pages-diy-nl-js" */),
  "component---src-pages-diy-ns-js": () => import("./../../../src/pages/diy/ns.js" /* webpackChunkName: "component---src-pages-diy-ns-js" */),
  "component---src-pages-diy-on-js": () => import("./../../../src/pages/diy/on.js" /* webpackChunkName: "component---src-pages-diy-on-js" */),
  "component---src-pages-diy-pe-js": () => import("./../../../src/pages/diy/pe.js" /* webpackChunkName: "component---src-pages-diy-pe-js" */),
  "component---src-pages-diy-qc-js": () => import("./../../../src/pages/diy/qc.js" /* webpackChunkName: "component---src-pages-diy-qc-js" */),
  "component---src-pages-diy-sk-js": () => import("./../../../src/pages/diy/sk.js" /* webpackChunkName: "component---src-pages-diy-sk-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-federal-timeline-js": () => import("./../../../src/pages/federal-timeline.js" /* webpackChunkName: "component---src-pages-federal-timeline-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-maps-js": () => import("./../../../src/pages/maps.js" /* webpackChunkName: "component---src-pages-maps-js" */),
  "component---src-pages-mp-name-game-js": () => import("./../../../src/pages/mp-name-game.js" /* webpackChunkName: "component---src-pages-mp-name-game-js" */),
  "component---src-pages-timeline-js": () => import("./../../../src/pages/timeline.js" /* webpackChunkName: "component---src-pages-timeline-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-single-province-js": () => import("./../../../src/templates/SingleProvince.js" /* webpackChunkName: "component---src-templates-single-province-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

